import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.supplers.name'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'wechat_id',
      name: 'input',
      label: i18n.t('form.supplers.wechat'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'whatsapp_number',
      name: 'input',
      label: i18n.t('form.supplers.whatsapp'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.supplers.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.supplers.email'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'remote_icon_url',
      name: 'image',
      label: i18n.t('form.supplers.img'),
      Object: {
        limit: 1,
        imageName: 'url',
        size: `${i18n.t('form.product.recommendedSize')}: 120x120`
      }
    }]
  }]
}
