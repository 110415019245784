import request from '@/utils/request'

export const getSimpleList = () => {
  return request({
    method: 'GET',
    url: '/cms/suppliers/simple_list'
  })
}

export const getSupplierMap = () => {
  return request({
    method: 'GET',
    url: '/cms/suppliers/supplier_map'
  })
}

export const getSuppliersSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/suppliers/setup'
  })
}

export const getSuppliers = (params) => {
  return request({
    method: 'GET',
    url: '/cms/suppliers',
    params
  })
}

export const getSuppliersEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/suppliers/${id}/preview`
  })
}

export const getSuppliersDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/suppliers/${id}`
  })
}

export const suppliersEdit = (data, id) => {
  return request({
    method: 'PUT',
    url: `/cms/suppliers/${id}`,
    data
  })
}

export const suppliersAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/suppliers',
    data
  })
}

export const deleteOnlineCouponDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/suppliers/${id}`
  })
}
