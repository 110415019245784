// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getSuppliersEditDetails, suppliersEdit } from '@/services/suppliers.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  name: 'suppliersEdit',
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getSuppliersEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          res.data.supplier.remote_icon_url = res.data.supplier.icon_url
          this.modifyData = res.data.supplier
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
      console.log(data)
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.icon_url = obj.remote_icon_url
      const params = {
        supplier: obj
      }
      suppliersEdit(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'suppliersManagerList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    }
  }
}
